// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #444;
  border: 1px solid #444;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  border: 1px solid #9ca3af;
  background: #9ca3af;
}
::-webkit-scrollbar-thumb:active {
  border: 1px double #9ca3af;
  background: #9ca3af;
}
::-webkit-scrollbar-track {
  border-style: ridge;
  border-width: 1px;
  border-color: transparent;
  background: transparent;
}
::-webkit-scrollbar-track:hover {
  border-style: ridge;
  border-width: 1px;
  border-color: transparent;
  background: transparent;
}
::-webkit-scrollbar-track:active {
  border-style: ridge;
  border-width: 1px;
  border-color: transparent;
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
`, "",{"version":3,"sources":["webpack://./src/css/scrollbar.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,WAAW;AACb;AACA;EACE,UAAU;EACV,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,0BAA0B;EAC1B,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,yBAAyB;EACzB,uBAAuB;AACzB;AACA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,yBAAyB;EACzB,uBAAuB;AACzB;AACA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,yBAAyB;EACzB,uBAAuB;AACzB;AACA;EACE,uBAAuB;AACzB;;AAEA,gDAAgD;AAChD;EACE,aAAa;AACf;;AAEA,4CAA4C;AAC5C;EACE,wBAAwB,EAAE,gBAAgB;EAC1C,qBAAqB,EAAE,YAAY;AACrC","sourcesContent":["::-webkit-scrollbar {\n  width: 4px;\n  height: 4px;\n}\n::-webkit-scrollbar-button {\n  width: 0px;\n  height: 0px;\n}\n::-webkit-scrollbar-thumb {\n  background: #444;\n  border: 1px solid #444;\n  border-radius: 50px;\n}\n::-webkit-scrollbar-thumb:hover {\n  border: 1px solid #9ca3af;\n  background: #9ca3af;\n}\n::-webkit-scrollbar-thumb:active {\n  border: 1px double #9ca3af;\n  background: #9ca3af;\n}\n::-webkit-scrollbar-track {\n  border-style: ridge;\n  border-width: 1px;\n  border-color: transparent;\n  background: transparent;\n}\n::-webkit-scrollbar-track:hover {\n  border-style: ridge;\n  border-width: 1px;\n  border-color: transparent;\n  background: transparent;\n}\n::-webkit-scrollbar-track:active {\n  border-style: ridge;\n  border-width: 1px;\n  border-color: transparent;\n  background: transparent;\n}\n::-webkit-scrollbar-corner {\n  background: transparent;\n}\n\n/* Hide scrollbar for Chrome, Safari and Opera */\n.no-scrollbar::-webkit-scrollbar {\n  display: none;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n.no-scrollbar {\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
