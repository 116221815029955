// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flip-card {
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  transform-style: preserve-3d;
}

/* Do an horizontal flip when flippable class is present */
.flip-card .flip-card-inner.flippable {
  transform: rotateY(180deg);
}

.flip-card .flip-card-inner.idle {
  transform: none;
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back { /* Safari */
  backface-visibility: hidden;
}

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
}
`, "",{"version":3,"sources":["webpack://./src/css/boardgame.css"],"names":[],"mappings":"AAAA;AACA;;AAEA,iEAAiE;AACjE;EACE,4BAA4B;AAC9B;;AAEA,0DAA0D;AAC1D;EACE,0BAA0B;AAC5B;;AAEA;EACE,eAAe;AACjB;;AAEA,qCAAqC;AACrC;kBAEuC,WAAW;EAChD,2BAA2B;AAC7B;;AAEA,wBAAwB;AACxB;EACE,0BAA0B;AAC5B","sourcesContent":[".flip-card {\n}\n\n/* This container is needed to position the front and back side */\n.flip-card-inner {\n  transform-style: preserve-3d;\n}\n\n/* Do an horizontal flip when flippable class is present */\n.flip-card .flip-card-inner.flippable {\n  transform: rotateY(180deg);\n}\n\n.flip-card .flip-card-inner.idle {\n  transform: none;\n}\n\n/* Position the front and back side */\n.flip-card-front,\n.flip-card-back {\n  -webkit-backface-visibility: hidden; /* Safari */\n  backface-visibility: hidden;\n}\n\n/* Style the back side */\n.flip-card-back {\n  transform: rotateY(180deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
